export const GET_USER = 'all/GET_USER';
export const DELETE_USER = 'all/DELETE_USER';
export const USER_DATA = 'all/USER_DATA';
export const USER_TYPE = 'all/USER_TYPE'; 

export const GET_LANGUAGES = 'all/GET_LANGUAGES'; 

export const SET_IS_MUST_CHANGE_PASSWORD = 'all/SET_IS_MUST_CHANGE_PASSWORD'
export const GET_IS_MUST_CHANGE_PASSWORD = 'all/GET_IS_MUST_CHANGE_PASSWORD'

export const GET_STATUS = 'all/GET_STATUS';
export const STATUS = 'all/STATUS'; 

export const REGISTER_USER = 'all/REGISTER_USER'; 
export const CREATE_AND_UPDATE_USER_PROFILE = 'all/CREATE_AND_UPDATE_USER_PROFILE';

export const GET_USERS = 'all/GET_USERS';
export const USERS_RESULT = 'all/USERS_RESULT';

export const GET_USER_PROFILE = 'all/GET_USER_PROFILE';
export const USER_PROFILE = 'all/USER_PROFILE';
export const DELETE_USER_PROFILE = 'all/DELETE_USER_PROFILE'; 
export const RESET_USER_PASSWORD = 'all/RESET_USER_PASSWORD';
export const SET_NEW_USER_PASSWORD = 'all/SET_NEW_USER_PASSWORD'

export const GET_CALENDAR_EVENTS = 'all/GET_CALENDAR_EVENTS'; 
export const SET_CALENDAR_EVENTS = 'all/SET_CALENDAR_EVENTS';
export const CALENDAR_EVENTS = 'all/CALENDAR_EVENTS'; 
export const POST_CALENDAR_EVENT = 'all/POST_CALENDAR_EVENT'; 
export const UPDATE_CALENDAR_EVENT = 'all/UPDATE_CALENDAR_EVENT'; 
export const SET_UPDATE_CALENDAR_EVENT = 'all/SET_UPDATE_CALENDAR_EVENT'; 
export const DELETE_CALENDAR_EVENT = 'all/DELETE_CALENDAR_EVENT'; 

export const POST_HOMEWORK = 'all/POST_HOMEWORK'; 
export const POST_HOMEWORK_FILES = 'all/POST_HOMEWORK_FILES';
export const SET_HOMEWORK_FILES = 'all/SET_HOMEWORK_FILES';
export const GET_HOMEWORK_FILES_LINKS = 'all/GET_HOMEWORK_FILES_LINKS';
export const GET_USER_HOMEWORK_LIST = 'all/GET_USER_HOMEWORK_LIST'; 
export const USER_HOMEWORK_LIST = 'all/USER_HOMEWORK_LIST';
export const USER_HOMEWORK_FILES_LINKS = 'all/USER_HOMEWORK_FILES_LINKS';
export const ARCHIVE_HOMEWORK = 'all/ARCHIVE_HOMEWORK';
export const GET_USER_ARCHIVE_HOMEWORK_LIST = 'all/GET_USER_ARCHIVE_HOMEWORK_LIST';
export const SET_USER_ARCHIVE_HOMEWORK_LIST = 'all/SET_USER_ARCHIVE_HOMEWORK_LIST';
export const USER_ARCHIVE_HOMEWORK_LIST = 'all/USER_ARCHIVE_HOMEWORK_LIST'; 
export const DELETE_HOMEWORK = 'all/DELETE_HOMEWORK';

export const SET_TOKEN = 'all/SET_TOKEN';

export const SET_INFO_MESSAGE = 'all/SET_INFO_MESSAGE';
export const GET_INFO_MESSAGE = 'all/GET_INFO_MESSAGE';
export const SET_INFO_POPUP_OPEN = 'all/SET_INFO_POPUP_OPEN'
export const GET_INFO_POPUP_OPEN = 'all/GET_INFO_POPUP_OPEN'
export const SET_TYPE_OF_INFO_MESSAGE = 'all/SET_TYPE_OF_INFO_MESSAGE'
export const GET_TYPE_OF_INFO_MESSAGE = 'all/GET_TYPE_OF_INFO_MESSAGE'