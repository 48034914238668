<template>
  <main-view />
</template>

<script>
  import MainView from '../components/MainView'

  export default {
    name: 'Home',

    components: {
      MainView,
    },
  }
</script>
